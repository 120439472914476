// Flow types automatically removed

import { Fragment, useState, useRef, useEffect, useCallback } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy'; // Components

import { FormattedMessage } from 'dibs-react-intl';
import FolderIconWrapper from '../FolderIconWrapper';
const defaultHeaderText = (
    <FormattedMessage id="dibs.portfolio.folder.Folder.header" defaultMessage="Save to Folder" />
);
export function Folder(props) {
    const [isAnimated, setIsAnimated] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsLoaded, setModalIsLoaded] = useState(false);
    const modalComponentRef = useRef(null);
    const {
        closeModal,
        doRefetch,
        isStrokeWidthHeavy,
        onAddItemSuccess,
        onRemoveItemSuccess,
        openOnRender,
        getModalComponent,
    } = props;
    const openModal = useCallback(() => {
        if (modalIsLoaded) {
            // Modal Component is already required
            setModalIsOpen(true);
        } else {
            // Modal Component is not yet required
            getModalComponent()
                .then(ModalComponent => {
                    modalComponentRef.current = ModalComponent;
                    setModalIsOpen(true);
                    setModalIsLoaded(true);
                })
                .catch(err => {
                    // eslint-disable-next-line
                    console.warn('failed to get ModalComponent', err);
                });
        }
    }, [modalIsLoaded, getModalComponent]);

    function handleFolderClick(e) {
        e.preventDefault();
        const trackingHooks = props.trackingHooks || {};

        if (props.onFolderClick) {
            props.onFolderClick();
        }

        if (trackingHooks.trackFolderClick) {
            trackingHooks.trackFolderClick(e);
        }

        openModal();
    }

    function handleCloseModal() {
        if (closeModal) {
            closeModal();
        }

        return new Promise(resolve => {
            setModalIsOpen(false);
            doRefetch().then(resolve);
        });
    }

    function handleAddItem(portfolio) {
        if (onAddItemSuccess) {
            onAddItemSuccess(portfolio);
        }

        setIsAnimated(true);
    }

    function handleRemoveItem() {
        if (onRemoveItemSuccess) {
            onRemoveItemSuccess();
        }

        setIsAnimated(true);
    }

    const hasOpenedOnRender = useRef(false);
    useEffect(() => {
        if (openOnRender && !hasOpenedOnRender.current) {
            openModal();
            hasOpenedOnRender.current = true;
        }
    }, [openOnRender, openModal]);
    const { folderPortfolioItems = [] } = props;
    const isInFolder = !!folderPortfolioItems.length;
    const ThisModalComponent = modalComponentRef.current;
    return (
        <Fragment>
            <FolderIconWrapper
                additionalButtonClass={props.additionalButtonClass}
                additionalIconClass={props.additionalIconClass}
                theme={props.theme}
                disabledButton={props.disabledButton}
                iconPosition={props.iconPosition || 'absolute'}
                iconText={props.iconText}
                isAnimated={isAnimated}
                isInFolder={isInFolder}
                isStrokeWidthHeavy={isStrokeWidthHeavy}
                type={props.type}
                onAnimationEnd={() => setIsAnimated(false)}
                onClick={handleFolderClick}
                showPlusIcon={props.showPlusIcon}
            />
            {modalIsLoaded && modalIsOpen && props.userId && ThisModalComponent && (
                <ThisModalComponent
                    allowSavingMultipleItems={props.allowSavingMultipleItems}
                    closeModal={handleCloseModal}
                    currentPortfolioId={props.currentPortfolioId}
                    environment={props.relay.environment}
                    loadPortfolioItemData={props.loadPortfolioItemData}
                    modalHeaderText={props.modalHeaderText || defaultHeaderText}
                    isOpen={modalIsOpen}
                    onAddItemError={props.onAddItemError}
                    onAddItemSuccess={handleAddItem}
                    onAddNoteError={props.onAddNoteError}
                    onAddNoteSuccess={props.onAddNoteSuccess}
                    onCreateFolderError={props.onCreateFolderError}
                    onCreateFolderSuccess={props.onCreateFolderSuccess}
                    onFolderRemove={props.onFolderRemove}
                    onFolderSave={props.onFolderSave}
                    onNewFolderSave={props.onNewFolderSave}
                    onRemoveItemError={props.onRemoveItemError}
                    onRemoveItemSuccess={handleRemoveItem}
                    primaryItemPk={props.itemId}
                    selectedItemIds={props.selectedItemIds}
                    selectedItemImages={props.selectedItemImages}
                    selectedItemTitles={props.selectedItemTitles}
                    singlePortfolioItem={props.singlePortfolioItem}
                    trackingHooks={props.trackingHooks}
                    userId={props.userId}
                    userIds={props.userIds || []}
                />
            )}
        </Fragment>
    );
}
export default createFragmentContainer(Folder, {
    folderPortfolioItems: graphql`
        fragment Folder_folderPortfolioItems on PortfolioItemV2 @relay(plural: true) {
            # we only check for the presence of a portfolio item
            __typename
        }
    `,
});
