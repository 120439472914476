/**
 * @generated SignedSource<<b7e48135d24efe1e4ed391e9cb019616>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Folder_folderPortfolioItems$data = ReadonlyArray<{
  readonly __typename: "PortfolioItemV2";
  readonly " $fragmentType": "Folder_folderPortfolioItems";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "Folder_folderPortfolioItems";
}>;
export type Folder_folderPortfolioItems$key = ReadonlyArray<{
  readonly " $data"?: Folder_folderPortfolioItems$data;
  readonly " $fragmentSpreads": FragmentRefs<"Folder_folderPortfolioItems">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Folder_folderPortfolioItems",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "type": "PortfolioItemV2",
  "abstractKey": null
};

(node as any).hash = "da196c25d5f0eb1a44e37c24aca70d52";

export default node;
