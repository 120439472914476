// Flow types automatically removed

import { useState } from 'react';
import { useIntl, defineMessages } from 'dibs-react-intl';
import FolderIcon from 'dibs-icons/exports/legacy/Folder';
import FolderPlusIcon from 'dibs-icons/exports/legacy/FolderPlus'; // Helpers

import classnames from 'classnames'; // Styles

import styles from './styles.scss'; // Types

const messages = defineMessages({
    label: {
        id: 'dibs-portfolio-folder.FolderWrapperIcon.label',
        defaultMessage: 'Save to folder',
    },
});

const FolderIconWrapper = props => {
    const intl = useIntl();
    const [isActive, setIsActive] = useState(false);
    const isButton = props.iconPosition === 'relative' || props.iconText;
    const isFolderPlusIcon = props.showPlusIcon && !props.isInFolder;
    const iconClasses = classnames(styles.icon, props.additionalIconClass, {
        [styles.isIcon]: !isButton,
        [styles.isButton]: isButton,
        [styles.isAnimated]: !isButton && props.isAnimated,
        [styles.isFilled]: props.isInFolder,
        [styles.isStrokeWidthHeavy]: props.isStrokeWidthHeavy,
        [styles.dark]: props.theme === 'dark',
        [styles.light]: props.theme === 'light',
    });
    const iconShadowClasses = classnames(styles.iconShadow, {
        [styles.isAnimated]: props.isAnimated,
        [styles.dark]: props.theme === 'dark',
        [styles.light]: props.theme === 'light',
    });
    const dataTn = props.isInFolder ? 'folder-saved-icon' : 'folder-save-icon';
    const Folder = isFolderPlusIcon ? FolderPlusIcon : FolderIcon;

    if (props.iconPosition === 'relative' || props.iconText) {
        const buttonClasses = classnames(styles.iconButton, props.additionalButtonClass, {
            [styles.disabled]: props.disabledButton,
        });
        const buttonIconClasses = classnames(styles.icon, props.additionalIconClass);
        return (
            // My name is Martynas B. and I confess that I'm disabling this rule because I didn't have time to refactor this component © Kostas
            // eslint-disable-next-line react/forbid-elements
            <button
                className={buttonClasses}
                data-tn="add-to-folder"
                onClick={props.onClick}
                type="button"
            >
                <Folder className={buttonIconClasses} />
                {props.iconText && <span className={styles.text}>{props.iconText}</span>}
            </button>
        );
    }

    function toggleActive(active) {
        if (props.type === 'button') {
            setIsActive(active);
        }
    }

    return (
        // My name is Martynas B. and I confess that I'm disabling this rule because I didn't have time to refactor this component © Kostas
        // eslint-disable-next-line react/forbid-elements
        <button
            aria-label={intl.formatMessage(messages.label)}
            className={classnames(styles.iconWrapper, props.additionalButtonClass, {
                [styles[props.type]]: props.type,
                [styles.isActive]: isActive,
                [styles.isStrokeWidthHeavy]: props.isStrokeWidthHeavy,
            })}
            onTouchStart={() => toggleActive(true)}
            onTouchEnd={() => toggleActive(false)}
            onClick={props.onClick}
            data-tn={dataTn}
            type="button"
        >
            <div
                className={classnames(styles.folder, {
                    [styles[props.type]]: props.type,
                })}
            >
                <Folder className={iconClasses} />
                <div className={iconShadowClasses} onAnimationEnd={props.onAnimationEnd} />
            </div>
        </button>
    );
};

FolderIconWrapper.defaultProps = {
    disabledButton: false,
    iconPosition: 'absolute',
    isInFolder: false,
    onClick: () => {},
    type: 'outline',
    isStrokeWidthHeavy: false,
};
export default FolderIconWrapper;
