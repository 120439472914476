import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FC } from 'react';

type FolderPlusProps = {
    className?: string;
};

const FolderPlus: FC<FolderPlusProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'folder-plus'}
            viewBox="0 0 250 250"
        >
            <path d="M237.6 66.2H129.4c-1.3 0-2.8-1.7-3.3-2.5l-.2-.3-31.2-39.3-.2-.3C90.8 19.9 87 18 82.7 18H9.5C4.8 18 0 21.5 0 28.3v192.5c0 8 6.4 11.2 10.6 11.4h228.6c4.2-.2 7.6-2.5 9.5-6.3 1.2-2.5 1.4-4.8 1.4-5.3V79c0-9.4-7.5-12.8-12.5-12.8zM125 99a.25.25 0 00-.25.25v48.5h-48.5a.25.25 0 000 .5h48.5v48.5a.25.25 0 00.5 0v-48.5h48.5a.25.25 0 000-.5h-48.5v-48.5A.25.25 0 00125 99z" />
        </svg>
    );
};

export default FolderPlus;
